import jwtDecode from "jwt-decode";
import moment from "moment";
import theme from "../styles/theme";
import { CandidateStatus } from "../_enums/candidateStatus.enum";
import { PayType } from "../_enums/payType.enum";
import { BookingDayStatus } from "../_enums/bookingDayStatus.enum";
import { BookingType } from "../_enums/bookingTypes.enum";
import { CandidatePay } from "../_enums/candidatePay.enum";
import { BookingDayInterface, BookingInterface, BookingInterfaceResponse, IBookingSplit } from "../_types/bookings";
import { ContactRoles } from "../_enums/contactRoles.enum";
import { ContactRoleItems } from "../config/formData";
import { TimesheetOptions } from "../components/forms/invoice";
import { ModuleUnionType } from "../components/dashboard/blocks";
import NavigationIcon from "../components/icons/navigation";
import { BookingStatus } from "../_enums/bookingStatus.enum";
import { TimesheetStatus } from "../_enums/timesheetStatus.enum";
import { RightToWork } from "../_enums/rightToWork.enum";
import { Note } from "../styles/forms/inputs";
import { FormQuestionModuleType, FormQuestionType, IFormQuestion } from "../state/context/formQuestions.store";
import { BookingCommissionInterface, calculateRepresentativeCommissionValue } from "./commission";
import { AgreeDisagreeEnum } from "../_enums/agreeDisagree.enum";
import { TargetTypes } from "../_enums/targets.enum";
import { Permissions } from "../_enums/permissions.enum";
import { BookingPlaceholderCandidateStatus } from "../_enums/bookingPlaceholderCandidateStatus.enum";
import { FilterTableType } from "../_enums/table.enum";
import { compress, decompress } from "compress-json";
import { UnitAmount } from "./helpers/profit";
import { OptionsType } from "../_types/form";
import { StatusSummaryCustomIcon } from "../components/icons/status";
import { RightToWorkItem } from "../hooks/useCustomerDocuments";

export const GetUserInformation = (TOKEN) => {
	return TOKEN ? jwtDecode(TOKEN) : null;
};

export const PermissionsValidate = (TOKEN, PERMISSIONS) => {
	if (TOKEN && PERMISSIONS) {
		const TokenDecode = jwtDecode(TOKEN);
		if (!TokenDecode) return false;

		const UserPermissions = TokenDecode.permissions;
		if (!UserPermissions) return false;

		const HasPermission = PERMISSIONS.find((permission) => UserPermissions.find((userPermission) => userPermission === permission));
		return HasPermission ? true : false;
	} else return false;
};

export const IsReadOnly = (TOKEN, assignee) => {
	if (TOKEN) {
		const decodedToken = jwtDecode(TOKEN);

		if (assignee === decodedToken.userId) return false;
		else return true;
	}
	return true;
};

export const IsTimesheetReadOnly = (TOKEN, assignee, invoiceNumber) => {
	if (TOKEN) {
		if (invoiceNumber) {
			if (!PermissionsValidate(TOKEN, [Permissions.CanEditInvoiceRaisedTimesheet])) {
				return true;
			}
		}

		const decodedToken = jwtDecode(TOKEN);

		if (assignee === decodedToken.userId) return false;
		else if (PermissionsValidate(TOKEN, [Permissions.CanManageAllBookings])) return false;
		else return true;
	}
	return true;
};

export const IsBookingReadOnly = (TOKEN, assignee) => {
	if (TOKEN) {
		const decodedToken = jwtDecode(TOKEN);

		if (assignee === decodedToken.userId) return false;
		else if (PermissionsValidate(TOKEN, [Permissions.CanManageAllBookings])) return false;
		else return true;
	}
	return true;
};

export const IsBookingPlacementReadOnly = (TOKEN, assignee) => {
	if (TOKEN) {
		const decodedToken = jwtDecode(TOKEN);

		if (assignee === decodedToken.userId) return false;
		else if (PermissionsValidate(TOKEN, [Permissions.CanManageAllSearchSelect])) return false;
		else return true;
	}
	return true;
};

export const IsCandidateReadOnly = (TOKEN, assignee) => {
	if (TOKEN) {
		const decodedToken = jwtDecode(TOKEN);

		if (assignee === decodedToken.userId) return false;
		else if (PermissionsValidate(TOKEN, [Permissions.CandidateComplianceManagerEdit])) return false;
		else return true;
	}
	return true;
};

export const GetDashboardIcon = (SLUG: ModuleUnionType) => {
	switch (SLUG) {
		case "Clients":
			return <NavigationIcon type="clients" currentColor />;
		case "Candidates":
			return <NavigationIcon type="candidates" currentColor />;
		case "Bookings":
			return <NavigationIcon type="bookings" currentColor />;
		case "Timesheets":
			return <NavigationIcon type="timesheets" currentColor />;
		case "Marketing":
			return <NavigationIcon type="marketing" currentColor />;
		case "Vacancy Manager":
			return <NavigationIcon type="placement" currentColor />;
	}
};

export const StringOrder = (Items, Order, Field) => {
	return Items.sort((a, b) =>
		Order === "Asc"
			? JSON.stringify(a[Field] || "")
					.toLowerCase()
					.replace(/ /g, "") >
			  JSON.stringify(b[Field] || "")
					.toLowerCase()
					.replace(/ /g, "")
				? 1
				: JSON.stringify(b[Field] || "")
						.toLowerCase()
						.replace(/ /g, "") >
				  JSON.stringify(a[Field] || "")
						.toLowerCase()
						.replace(/ /g, "")
				? -1
				: 0
			: JSON.stringify(a[Field] || "")
					.toLowerCase()
					.replace(/ /g, "") <
			  JSON.stringify(b[Field] || "")
					.toLowerCase()
					.replace(/ /g, "")
			? 1
			: JSON.stringify(b[Field] || "")
					.toLowerCase()
					.replace(/ /g, "") <
			  JSON.stringify(a[Field] || "")
					.toLowerCase()
					.replace(/ /g, "")
			? -1
			: 0
	);
};

export const NumberOrder = (Items, Order, Field) => {
	return Items.sort((a, b) =>
		Order === "Asc"
			? parseInt(a[Field] || 0) > parseInt(b[Field] || 0)
				? 1
				: parseInt(b[Field] || 0) > parseInt(a[Field] || 0)
				? -1
				: 0
			: parseInt(a[Field] || 0) < parseInt(b[Field] || 0)
			? 1
			: parseInt(b[Field] || 0) < parseInt(a[Field] || 0)
			? -1
			: 0
	);
};

export const DateOrder = (Items, Order, Field) => {
	return Items.sort((a, b) => (Order === "Asc" ? new Date(a[Field]).getTime() - new Date(b[Field]).getTime() : new Date(b[Field]).getTime() - new Date(a[Field]).getTime()));
};

export const UpdateFilter = (TYPE, Config) => {
	if (TYPE === "standard") {
		Config.pageNo = Config.pageNo;
	} else if (TYPE === "prev") {
		Config.pageNo = Config.pageNo - 1;
	} else if (TYPE === "next") {
		Config.pageNo = Config.pageNo + 1;
	}
	return Config;
};

export const ProfitMargin = (PAY_RATE, CHARGE_RATE, DAYS, PAY_TYPE, ON_COSTS, CANDIDATAE_PAY_TYPE, BOOKING_TYPE, EXCLUDE?, BOOKING_SPLITS?, FILTERED_USER?, BREAKS?) => {
	if (EXCLUDE) return 0;

	const pr = parseFloat(PAY_RATE || 0); // booking pay rate
	const cr = parseFloat(CHARGE_RATE || 0); // booking charge rate
	const oc = parseFloat(ON_COSTS || 0); // business on costs

	let bookingPayRate = 0;
	let bookingChargeRate = 0;

	// for each day in the booking
	for (let i = 0, day: BookingDayInterface; (day = DAYS[i++]); ) {
		let startMoment = moment(day.start);
		let endMoment = moment(day.end);

		// Check if end time is before start time (overnight shift)
		if (endMoment.isBefore(startMoment)) {
			endMoment = endMoment.add(1, "day");
		}
		let minutes = endMoment.diff(startMoment, "minutes"); // if the pay type is hourly the minutes will be used to calculate the pay rate

		if (BREAKS) {
			const breaks = parseFloat(BREAKS);
			minutes = minutes - breaks * 60;
		}

		const dpr = day.payRate ? parseFloat(day.payRate) : pr; // day pay rate
		const dcr = day.chargeRate ? parseFloat(day.chargeRate) : cr; // day pay rate

		let dayPayRate = 0;

		bookingChargeRate = !PAY_TYPE || PAY_TYPE == PayType.Day ? (bookingChargeRate = bookingChargeRate + dcr) : (bookingChargeRate = bookingChargeRate + (dcr * minutes) / 60);

		// if the pay type is daily
		if (!PAY_TYPE || PAY_TYPE == PayType.Day) {
			// booking pay rate * (1 + on costs) / 100, gives us the pay rate with on costs included or not, minus this from the booking pay rate
			dayPayRate = CANDIDATAE_PAY_TYPE == CandidatePay.Paye && (BOOKING_TYPE === BookingType.Standard || BOOKING_TYPE === BookingType.Tutor) ? dpr + dpr * (oc / 100) : dpr;
		} else {
			// if the pay type is daily
			// booking pay rate * (1 + on costs) / 100, gives us the pay rate with on costs included or not, minus this from the booking pay rate, times by the number of hours
			let hourlyRateWithOncosts =
				CANDIDATAE_PAY_TYPE == CandidatePay.Paye && (BOOKING_TYPE === BookingType.Standard || BOOKING_TYPE === BookingType.Tutor)
					? dpr * (1 + oc / 100) // Hourly rate with on-costs
					: dpr;

			dayPayRate = (hourlyRateWithOncosts * minutes) / 60;
		}

		bookingPayRate = bookingPayRate + dayPayRate;
	}

	let profitMargin = bookingChargeRate - bookingPayRate;

	if (BOOKING_SPLITS?.length && FILTERED_USER) {
		let splitPercentage = 100;
		const matchingFilteredUser = BOOKING_SPLITS.find((bookingSplit: IBookingSplit) => bookingSplit.user === FILTERED_USER);
		if (!matchingFilteredUser) {
			const bookingSplitTotal = BOOKING_SPLITS?.reduce((acc, curr) => acc + parseInt(curr.split as unknown as string), 0);
			splitPercentage = 100 - bookingSplitTotal;
		} else {
			splitPercentage = matchingFilteredUser.split;
		}

		profitMargin = (splitPercentage / 100) * profitMargin;
	}

	return profitMargin;
};

export const CalculateCommission = (bookings: BookingInterface[], dateRange: any, filteredUser?: string): { totalCommission: number; mixedCommissionTypes: boolean } => {
	let totalCommission = 0;
	let mixedCommissionTypes = false;

	let bookingsWithProfit = bookings
		.filter((booking) => !booking.excludeFromProfitMargins && !booking.excludeFromCommission)
		.map((booking: BookingInterfaceResponse) => ({
			assignee: booking.assignee?._id,
			commissionType: booking.commissionType,
			commissionWeekly: booking.commissionWeekly ?? false,
			commission: booking.commission ?? [],
			chargeRate: booking.chargeRate,
			payRate: booking.payRate,
			oncosts: booking.oncosts,
			days: booking.days.map((day) => ({
				...day,
				dayProfit: ProfitMargin(
					booking.payRate,
					booking.chargeRate,
					[day],
					booking.payType,
					booking.oncosts,
					booking.candidatePayType,
					booking.type,
					undefined,
					booking.bookingSplits,
					filteredUser,
					booking.breaks
				),
			})),
			bookingProfit: ProfitMargin(
				booking.payRate,
				booking.chargeRate,
				booking.days,
				booking.payType,
				booking.oncosts,
				booking.candidatePayType,
				booking.type,
				undefined,
				booking.bookingSplits,
				filteredUser,
				booking.breaks
			),
			bookingSplits: booking.bookingSplits,
		}))
		.filter((booking) => (booking.assignee && booking.commission.length) || booking.bookingSplits?.length);

	const commissionTypes = bookingsWithProfit.map((item) => item.commissionType);
	const uniqueCommissionTypes = commissionTypes.filter((value, index, self) => {
		return self.indexOf(value) === index;
	});

	// if (uniqueCommissionTypes.length > 1) {
	// 	mixedCommissionTypes = true;
	// 	return { totalCommission, mixedCommissionTypes };
	// }

	const groupedByAssignee = bookingsWithProfit.reduce((acc, item) => {
		if (!acc[item.assignee]) {
			acc[item.assignee] = [];
		}
		acc[item.assignee].push(item);
		return acc;
	}, {});

	const assigneeBookings = Object.values(groupedByAssignee);
	const formattedAssigneeBookings = [];

	for (let i = 0, assigneeBooking; (assigneeBooking = assigneeBookings[i++]); ) {
		const booking = {
			...assigneeBooking[0],
			days: [],
			bookingProfit: 0,
		};
		for (let b = 0, singleBooking; (singleBooking = assigneeBooking[b++]); ) {
			if (singleBooking.days?.length) {
				booking.days.push(...singleBooking.days);
				booking.bookingProfit += singleBooking.bookingProfit;
			}
		}

		formattedAssigneeBookings.push(booking);
	}

	for (let i = 0, assigneeBooking; (assigneeBooking = formattedAssigneeBookings[i++]); ) {
		const commission = calculateRepresentativeCommissionValue([assigneeBooking] as unknown as BookingCommissionInterface[], dateRange.start, dateRange.end, filteredUser);
		totalCommission += commission;
	}

	// totalCommission = calculateRepresentativeCommissionValue(bookingsWithProfit as unknown as BookingCommissionInterface[], dateRange.start, dateRange.end);

	// const assigneeProfits: any = {};

	// bookingsWithProfit.forEach((booking: any) => {
	// 	if (!assigneeProfits[booking.assignee as unknown as string]) {
	// 		assigneeProfits[booking.assignee as unknown as string] = 0;
	// 	}
	// 	assigneeProfits[booking.assignee as unknown as string] += booking.bookingProfit;
	// });

	// for (let assignee in assigneeProfits) {
	// 	const sampleBooking = bookingsWithProfit.find((b: any) => b.assignee === assignee); // assuming commission structure is the same for all bookings of the same assignee
	// 	const commission = calculateCommissionForAssignee(assigneeProfits[assignee], sampleBooking, 1);
	// 	totalCommission += commission;
	// }

	return { totalCommission, mixedCommissionTypes };
};

const calculateCommissionForAssignee = (assigneeProfit, commissionData, divisor) => {
	let commissionAmount = 0;

	// Sort commission thresholds from highest to lowest
	let sortedThresholds = commissionData.commission.slice().sort((a, b) => b.min - a.min);

	// Check for commissionType
	if (commissionData.commissionType === 1) {
		for (let t = 0, threshold; (threshold = sortedThresholds[t++]); ) {
			// const min = threshold.min / divisor;

			const removal = sortedThresholds[sortedThresholds.length - 1];

			const min = threshold.min;
			if (assigneeProfit >= min) {
				commissionAmount = (assigneeProfit - removal?.min ?? 0) * (threshold.commission / 100);
				break; // exit once the right threshold is found
			}
		}
	} else if (commissionData.commissionType === 2) {
		let remainingProfit = assigneeProfit;
		for (let threshold of sortedThresholds) {
			const min = threshold.min;
			if (remainingProfit >= min) {
				let profitAboveThreshold = remainingProfit - min;
				commissionAmount += profitAboveThreshold * (threshold.commission / 100);
				remainingProfit = min; // subtract the amount above the threshold
			}
		}
	}

	return commissionAmount;
};

export const round = (number: number, decimals: number) => {
	return Number(Math.round((number + "e" + decimals) as unknown as number) + "e-" + decimals);
};

export const StatusColour = (STATUS) => {
	if (STATUS === 1) {
		return theme.trafficLightAmber;
	} else if (STATUS === 2) {
		return theme.trafficLightGreen;
	} else if (STATUS === 3) {
		return theme.trafficLightRed;
	}
};

export const BookingStatusColour = (STATUS) => {
	if (STATUS === 1) {
		return theme.trafficLightGreen;
	} else if (STATUS === 2) {
		return theme.trafficLightRed;
	}
};

export const BookingDayStatusClassname = (status: BookingDayStatus) => {
	if (status === BookingDayStatus.Active) return "bookingDayInactive bookingDayActive";
	else if (status === BookingDayStatus.ActOfGod) return "bookingDayInactive bookingDayActofGod";
	else if (status === BookingDayStatus.ClientCancelled) return "bookingDayInactive bookingDayCancelled";
	else if (status === BookingDayStatus.NoShow) return "bookingDayInactive bookingDayNoShow";
	else if (status === BookingDayStatus.Sickness) return "bookingDayInactive bookingDaySickness";
	else if (status === BookingDayStatus.PersonalAppt) return "bookingDayInactive bookingDayPersonalAppt";
	return "";
};

export const BookingTypeName = (type: BookingType) => {
	if (type === BookingType.Standard) return "Placement";
	else if (type === BookingType.Interview) return "Interview";
	else if (type === BookingType.Trial) return "Trial";
	else if (type === BookingType.Perm) return "Perm";
	else if (type === BookingType.Tutor) return "Tuition";

	return "";
};

export const ToggleColour = (STATUS) => {
	if (STATUS) {
		return theme.trafficLightGreen;
	} else {
		return theme.trafficLightRed;
	}
};

export const MarketingStatFriendlyName = (STAT) => {
	if (STAT === "processed") return "Processed";
	else if (STAT === "deferred") return "Deferred";
	else if (STAT === "delivered") return "Delivered";
	else if (STAT === "open") return "Open";
	else if (STAT === "click") return "Click";
	else if (STAT === "bounce") return "Bounce";
	else if (STAT === "dropped") return "Dropped";
	else if (STAT === "spamreport") return "Spam Report";
	else if (STAT === "unsubscribe") return "Unsubscribe";
	else if (STAT === "group_unsubscribe") return "Group Unsubscribe";
	else if (STAT === "group_resubscribe") return "Group Resubscribe";
	else return "Unknown";
};

export const ConvertCandidateStatus = (STATUS: CandidateStatus, STATUSES?: OptionsType[]) => {
	if (STATUS === CandidateStatus.Approved) return "Approved";
	else if (STATUS === CandidateStatus.Future) {
		const matchingStatus = STATUSES?.find?.((item) => item.ID == STATUS);
		return matchingStatus?.Name || "Future";
	} else if (STATUS === CandidateStatus.Pre) {
		const matchingStatus = STATUSES?.find?.((item) => item.ID == STATUS);
		return matchingStatus?.Name || "Pre-Pending";
	} else if (STATUS === CandidateStatus.Pending) return "Pending";
	else if (STATUS === CandidateStatus.Hold) {
		const matchingStatus = STATUSES?.find?.((item) => item.ID === STATUS);
		return matchingStatus?.Name || "Provisional";
	} else if (STATUS === CandidateStatus.Rejected) return "Archived";
	else if (STATUS === CandidateStatus.Enquired) return "Applicant";
	else if (STATUS === CandidateStatus.DNC) {
		const matchingStatus = STATUSES?.find?.((item) => item.ID === STATUS);
		return matchingStatus?.Name || "Do Not Contact";
	} else return "N/A";
};

export const ConvertTimesheetDetailStatus = (STATUS: CandidateStatus, type: "Client" | "Candidate") => {
	if (STATUS === CandidateStatus.Approved) return `${type} Approved`;
	else if (STATUS === CandidateStatus.Pending) return `${type} Pending`;
	else if (STATUS === CandidateStatus.Rejected) return `${type} Queried`;
	else return "N/A";
};

export const ConvertCandidateInterest = (INTEREST: BookingPlaceholderCandidateStatus) => {
	if (INTEREST === BookingPlaceholderCandidateStatus.Interested) return "Interested";
	else if (INTEREST === BookingPlaceholderCandidateStatus.Pending) return "Pending";
	else if (INTEREST === BookingPlaceholderCandidateStatus.NotInterested) return "Not Interested";
	else return "N/A";
};

export const ConvertTimesheetStatus = (STATUS: TimesheetStatus) => {
	if (STATUS === TimesheetStatus.Approved) return "Approved";
	else if (STATUS === TimesheetStatus.Pending) return "Pending";
	else if (STATUS === TimesheetStatus.Rejected) return "Queried";
	else return "N/A";
};

export const ConvertBookingDayStatus = (STATUS: BookingDayStatus) => {
	if (STATUS === BookingDayStatus.Active) return "Active";
	else if (STATUS === BookingDayStatus.ActOfGod) return "Act of God";
	else if (STATUS === BookingDayStatus.ClientCancelled) return "Cancelled";
	else if (STATUS === BookingDayStatus.NoShow) return "No Show";
	else if (STATUS === BookingDayStatus.Sickness) return "Sickness";
	else if (STATUS === BookingDayStatus.PersonalAppt) return "Personal Appt";
	else return "N/A";
};

export const BookingDayStatusColor = (STATUS: BookingDayStatus) => {
	if (STATUS === BookingDayStatus.Active) return theme.green;
	else return theme.red;
};

export const ConvertContactRole = (ROLE: ContactRoles) => {
	return ContactRoleItems.find((item) => item.ID === ROLE)?.Name ?? "N/A";
};

const dataURLtoBlob = (dataurl: string) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
};

export const resizeImage = (file: File, height?: number, width?: number, minSizes?: { width: number; height: number }): Promise<Blob> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);

		reader.onload = (event: ProgressEvent<FileReader>) => {
			const img = document.createElement("img");

			img.onload = (imgEvent: ProgressEvent) => {
				let h, w;

				if (height && width) {
					// if (img.height < height || img.width < width) {
					// 	return reject(new Error(`Image height must be at least ${height}px. Width must be at least ${width}px`));
					// }

					h = img.height <= height ? img.height : height;
					w = img.width <= width ? img.width : width;
				} else if (height && !width) {
					// if (img.height < height) {
					// 	return reject(new Error(`Image height must be at least ${height}px.`));
					// }

					h = img.height <= height ? img.height : height;
					w = img.width * (h / img.height);
				} else if (!height && width) {
					// if (img.width < width) {
					// 	return reject(new Error(`Image height must be at least ${width}px.`));
					// }

					w = img.width <= width ? img.width : width;
					h = img.height * (w / img.width);
				} else {
					h = img.height;
					w = img.width;
				}

				h = Math.round(h);
				w = Math.round(w);

				const canvas = document.createElement("canvas");
				if (minSizes) {
					canvas.width = Math.max(w, minSizes.width);
					canvas.height = Math.max(h, minSizes.height);
					const ctx = canvas.getContext("2d");

					// Fill the canvas with white background
					ctx.fillStyle = "#FFFFFF";
					ctx.fillRect(0, 0, canvas.width, canvas.height);

					// Calculate position to center the image on the canvas
					const posX = (canvas.width - w) / 2;
					const posY = (canvas.height - h) / 2;

					// Draw the resized image onto the center of the canvas
					ctx.drawImage(img, 0, 0, img.width, img.height, posX, posY, w, h);
				} else {
					canvas.height = h;
					canvas.width = w;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, w, h);
				}

				canvas.toBlob((blob) => {
					if (!blob) {
						reject(new Error("Failed to create blob from canvas"));
						return;
					}

					// Create a File object with the same name
					const resizedFile = new File([blob], file.name, { type: blob.type });
					resolve(resizedFile);
				}, file.type);
			};

			img.src = event.target.result as string;
		};
	});
};

export const ConvertFileTypeToMimeType = (fileType: string) => {
	const types = {
		//   File Extension   MIME Type
		abs: "audio/x-mpeg",
		ai: "application/postscript",
		aif: "audio/x-aiff",
		aifc: "audio/x-aiff",
		aiff: "audio/x-aiff",
		aim: "application/x-aim",
		art: "image/x-jg",
		asf: "video/x-ms-asf",
		asx: "video/x-ms-asf",
		au: "audio/basic",
		avi: "video/x-msvideo",
		avx: "video/x-rad-screenplay",
		bcpio: "application/x-bcpio",
		bin: "application/octet-stream",
		bmp: "image/bmp",
		body: "text/html",
		cdf: "application/x-cdf",
		cer: "application/pkix-cert",
		class: "application/java",
		cpio: "application/x-cpio",
		csh: "application/x-csh",
		css: "text/css",
		dib: "image/bmp",
		doc: "application/msword",
		dtd: "application/xml-dtd",
		dv: "video/x-dv",
		dvi: "application/x-dvi",
		eot: "application/vnd.ms-fontobject",
		eps: "application/postscript",
		etx: "text/x-setext",
		exe: "application/octet-stream",
		gif: "image/gif",
		gtar: "application/x-gtar",
		gz: "application/x-gzip",
		hdf: "application/x-hdf",
		hqx: "application/mac-binhex40",
		htc: "text/x-component",
		htm: "text/html",
		html: "text/html",
		ief: "image/ief",
		jad: "text/vnd.sun.j2me.app-descriptor",
		jar: "application/java-archive",
		java: "text/x-java-source",
		jnlp: "application/x-java-jnlp-file",
		jpe: "image/jpeg",
		jpeg: "image/jpeg",
		jpg: "image/jpeg",
		js: "application/javascript",
		jsf: "text/plain",
		json: "application/json",
		jspf: "text/plain",
		kar: "audio/midi",
		latex: "application/x-latex",
		m3u: "audio/x-mpegurl",
		mac: "image/x-macpaint",
		man: "text/troff",
		mathml: "application/mathml+xml",
		me: "text/troff",
		mid: "audio/midi",
		midi: "audio/midi",
		mif: "application/x-mif",
		mov: "video/quicktime",
		movie: "video/x-sgi-movie",
		mp1: "audio/mpeg",
		mp2: "audio/mpeg",
		mp3: "audio/mpeg",
		mp4: "video/mp4",
		mpa: "audio/mpeg",
		mpe: "video/mpeg",
		mpeg: "video/mpeg",
		mpega: "audio/x-mpeg",
		mpg: "video/mpeg",
		mpv2: "video/mpeg2",
		ms: "application/x-wais-source",
		nc: "application/x-netcdf",
		oda: "application/oda",
		odb: "application/vnd.oasis.opendocument.database",
		odc: "application/vnd.oasis.opendocument.chart",
		odf: "application/vnd.oasis.opendocument.formula",
		odg: "application/vnd.oasis.opendocument.graphics",
		odi: "application/vnd.oasis.opendocument.image",
		odm: "application/vnd.oasis.opendocument.text-master",
		odp: "application/vnd.oasis.opendocument.presentation",
		ods: "application/vnd.oasis.opendocument.spreadsheet",
		odt: "application/vnd.oasis.opendocument.text",
		otg: "application/vnd.oasis.opendocument.graphics-template",
		oth: "application/vnd.oasis.opendocument.text-web",
		otp: "application/vnd.oasis.opendocument.presentation-template",
		ots: "application/vnd.oasis.opendocument.spreadsheet-template",
		ott: "application/vnd.oasis.opendocument.text-template",
		ogx: "application/ogg",
		ogv: "video/ogg",
		oga: "audio/ogg",
		ogg: "audio/ogg",
		otf: "application/x-font-opentype",
		spx: "audio/ogg",
		flac: "audio/flac",
		anx: "application/annodex",
		axa: "audio/annodex",
		axv: "video/annodex",
		xspf: "application/xspf+xml",
		pbm: "image/x-portable-bitmap",
		pct: "image/pict",
		pdf: "application/pdf;charset=utf-8",
		pgm: "image/x-portable-graymap",
		pic: "image/pict",
		pict: "image/pict",
		pls: "audio/x-scpls",
		png: "image/png",
		pnm: "image/x-portable-anymap",
		pnt: "image/x-macpaint",
		ppm: "image/x-portable-pixmap",
		ppt: "application/vnd.ms-powerpoint",
		pps: "application/vnd.ms-powerpoint",
		ps: "application/postscript",
		psd: "image/vnd.adobe.photoshop",
		qt: "video/quicktime",
		qti: "image/x-quicktime",
		qtif: "image/x-quicktime",
		ras: "image/x-cmu-raster",
		rdf: "application/rdf+xml",
		rgb: "image/x-rgb",
		rm: "application/vnd.rn-realmedia",
		roff: "text/troff",
		rtf: "application/rtf",
		rtx: "text/richtext",
		sfnt: "application/font-sfnt",
		sh: "application/x-sh",
		shar: "application/x-shar",
		sit: "application/x-stuffit",
		snd: "audio/basic",
		src: "application/x-wais-source",
		sv4cpio: "application/x-sv4cpio",
		sv4crc: "application/x-sv4crc",
		svg: "image/svg+xml",
		svgz: "image/svg+xml",
		swf: "application/x-shockwave-flash",
		t: "text/troff",
		tar: "application/x-tar",
		tcl: "application/x-tcl",
		tex: "application/x-tex",
		texi: "application/x-texinfo",
		texinfo: "application/x-texinfo",
		tif: "image/tiff",
		tiff: "image/tiff",
		tr: "text/troff",
		tsv: "text/tab-separated-values",
		ttf: "application/x-font-ttf",
		txt: "text/plain",
		ulw: "audio/basic",
		ustar: "application/x-ustar",
		vxml: "application/voicexml+xml",
		xbm: "image/x-xbitmap",
		xht: "application/xhtml+xml",
		xhtml: "application/xhtml+xml",
		xls: "application/vnd.ms-excel",
		xml: "application/xml",
		xpm: "image/x-xpixmap",
		xsl: "application/xml",
		xslt: "application/xslt+xml",
		xul: "application/vnd.mozilla.xul+xml",
		xwd: "image/x-xwindowdump",
		vsd: "application/vnd.visio",
		wav: "audio/x-wav",
		wbmp: "image/vnd.wap.wbmp",
		wml: "text/vnd.wap.wml",
		wmlc: "application/vnd.wap.wmlc",
		wmls: "text/vnd.wap.wmlsc",
		wmlscriptc: "application/vnd.wap.wmlscriptc",
		wmv: "video/x-ms-wmv",
		woff: "application/font-woff",
		woff2: "application/font-woff2",
		wrl: "model/vrml",
		wspolicy: "application/wspolicy+xml",
		z: "application/x-compress",
		zip: "application/zip",
	};
	return types[fileType];
};

export const SaveFile = (blob: Blob, filename: string) => {
	const fileURL = window.URL.createObjectURL(blob);
	let alink = document.createElement("a");
	alink.href = fileURL;
	alink.target = "_blank";
	alink.download = filename;
	document.body.appendChild(alink);
	alink.click();
	// document.body.removeChild(alink);
};

export const NestedTargetValue = (data: any, name: string, value: unknown) => {
	const levels = name.split(".").map((name) => name);
	let updatedData = { ...data };
	let property = updatedData;
	for (let i = 0, item; (item = levels[i++]); ) {
		if (levels[i]) {
			property = property[levels[i - 1]];
		}
	}
	property[levels[levels.length - 1]] = value;
	return updatedData;
};

export const CalculateTax = (value: number, type: "tax" | "total"): string => {
	try {
		const numericValue = typeof value === "string" ? parseFloat(value) : value;

		if (isNaN(numericValue)) {
			throw new Error("Invalid value");
		}

		if (type !== "tax" && type !== "total") {
			throw new Error("Invalid type");
		}

		const divisor = 1.2;
		let result: number;

		if (type === "tax") {
			result = numericValue * divisor - numericValue;
		} else {
			result = numericValue * divisor;
		}

		return result.toFixed(2);
	} catch (error) {
		return "";
	}
};

type IBookingDayToRemove = BookingInterface & { flaggedForRemoval: boolean };
export const CalculateLineItems = (timesheet: TimesheetOptions) => {
	const bookings = [timesheet];

	const newBookings = [];

	for (let i = 0, booking; (booking = bookings[i++]); ) {
		const bookingDiffDays = [];
		for (let d = 0, day; (day = (booking.days as BookingDayInterface[])[d++]); ) {
			if ((day.chargeRate && day.chargeRate !== booking.booking.chargeRate) || (day.payRate && day.payRate !== booking.booking.payRate)) {
				day.flaggedForRemoval = true;
				bookingDiffDays.push(day as BookingDayInterface);
			}
		}
		const grouped = bookingDiffDays.reduce((result: any, item) => {
			const key = item._id + item.chargeRate + "-" + item.payRate;
			const group = result[key] || [];
			group.push(item);
			result[key] = group;
			return result;
		}, {});

		const result = Object.values(grouped);
		for (let nb = 0, newBooking; (newBooking = result[nb++] as BookingDayInterface[]); ) {
			newBookings.push({
				...booking,
				...{
					booking: {
						...booking.booking,
						...{
							chargeRate: newBooking[0].chargeRate,
							payRate: newBooking[0].payRate,
							days: newBooking,
						},
					},
				},
				days: newBooking,
			});
		}
	}

	const existingBookingsUpdated = bookings.map((booking) => ({
		...booking,
		days: (booking.days as IBookingDayToRemove[]).filter((bookingDay) => !bookingDay.flaggedForRemoval) as BookingDayInterface[],
	}));

	const newBookingsFormatted = [...existingBookingsUpdated, ...newBookings]
		.map((booking) => ({
			...booking,
			unitAmount: booking.days.reduce((acc, day) => acc + UnitAmount(day, booking.booking?.payType, booking.booking?.chargeRate, booking.booking?.breaks), 0),
			Name: `${booking.candidateName} - ${booking.clientName}: ${moment(booking.days[0]?.start).format("DD/MM/YYYY HH:mm")}${
				timesheet.booking?.type !== BookingType.Perm ? ` - ${moment(booking.days[booking.days.length - 1]?.end).format("DD/MM/YYYY HH:mm")}` : ""
			}`,
			Description: `${(booking.days || [])
				?.filter?.((day) => day.status == BookingDayStatus.Active)
				.map(
					(day) =>
						`${moment(day.start).format("DD/MM/YYYY HH:mm")}${timesheet.booking?.type !== BookingType.Perm ? `- ${moment(day.end).format("HH:mm")}` : ""}: ${
							day.chargeRate ? `£${day.chargeRate}` : `£${booking.booking.chargeRate}`
						}`
				)
				.join("\n")}${timesheet.booking?.poNumber ? `\nPO: ${timesheet.booking?.poNumber}` : ""}${
				timesheet.booking?.type == BookingType.Tutor && timesheet.booking?.studentName ? `\nTuition: ${timesheet.booking?.studentName}` : ""
			}`,
		}))
		.sort((a, b) => a.ID.localeCompare(b.ID));

	return newBookingsFormatted;
};

export const ActiveBookingDays = (days: BookingDayInterface[]) => {
	return days.filter((day: BookingDayInterface) => !day.status || day.status === BookingDayStatus.Active);
};

export const ConvertToSuffixFormat = (postcode?: string) => {
	if (!postcode) return "";

	postcode = postcode.replace(/ /g, "");

	if (!isValidPostcode(postcode)) {
		return postcode;
	}

	let outwardCode = "";

	if (postcode.length === 7) {
		outwardCode = postcode.slice(0, -3);
	} else if (postcode.length === 6) {
		outwardCode = postcode.slice(0, -2);
	}

	return outwardCode;
};

function isValidPostcode(postcode: string) {
	const regex = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/g;
	return regex.test(postcode);
}

export const BookingActiveStatus = (status: BookingStatus, start: string, end: string) => {
	let statusString = "";
	if (!start || !end) return statusString;

	if (status === BookingStatus.Cancelled) return statusString;

	if (moment().startOf("day").isSameOrAfter(moment(start).startOf("day")) && moment().startOf("day").isSameOrBefore(moment(end).startOf("day"))) statusString = " - In Progress";
	else if (moment().startOf("day").isAfter(end)) statusString = " - Booking Complete";
	else if (moment().startOf("day").isBefore(start)) statusString = " - Booking Coming Up";

	return statusString;
};

export const DocumentMessage = (documentType: RightToWork) => {
	if (documentType == RightToWork.ProofOfAddress) {
		return <Note>We require x2 proof of addresses total and that they must be dated within 3 months of the current date.</Note>;
	} else if (documentType == RightToWork.CV) {
		return <Note>We require an accurate CV with all gaps filled with dating and reasoning for being out of work where applicable.</Note>;
	} else if (documentType == RightToWork.DBS) {
		return <Note>We require the front AND back of the DBS to be uploaded.</Note>;
	} else if (documentType == RightToWork.Reference) {
		return <Note>We require reference details for all previous workplaces within the past 5 years where applicable.</Note>;
	}

	return null;
};

export const ShowFormQuestion = (formQuestions: IFormQuestion[], category: FormQuestionModuleType, type: FormQuestionType) => {
	return formQuestions?.find((question) => question.type === category && question.question === type) ? true : false;
};

export const IsRightToWorkDoc = (documentType?: RightToWork, RightToWorkAvailableItems?: RightToWorkItem[]) => {
	let doc = RightToWorkAvailableItems?.find?.((item) => item.ID === Number(documentType));
	if (doc?.hasExpiry) {
		return true;
	}

	return false;
};

export const IsIssueDateDoc = (documentType?: RightToWork, RightToWorkAvailableItems?: RightToWorkItem[]) => {
	let doc = RightToWorkAvailableItems?.find?.((item) => item.ID === Number(documentType));
	if (doc?.hasIssued) {
		return true;
	}

	return false;
};

export const IsAdminLastCheckedDateDoc = (documentType?: RightToWork) => {
	let docType = typeof documentType === "string" ? parseInt(documentType) : documentType;
	if ([RightToWork.DBSUpdateService].includes(docType)) {
		return true;
	}

	return false;
};

export const IsAdminRightToWorkDoc = (documentType?: RightToWork) => {
	let docType = typeof documentType === "string" ? parseInt(documentType) : documentType;
	if (
		[
			RightToWork.DBS,
			RightToWork.DrivingLicense,
			RightToWork.EuropeanPassport,
			RightToWork.UKPassport,
			RightToWork.Visa,
			RightToWork.RTWVerification,
			RightToWork.HomeOfficeShareCode,
			RightToWork.ForeignPassport,
			RightToWork.SafeguardingCertificateWithExpiry,
			RightToWork.DfeCheck,
			RightToWork.EWCConfirmationInternalOnly,
			RightToWork.PreventTraining,
			RightToWork.BiometricResidencePermit,
			RightToWork.Other,
			RightToWork.Qualification,
			RightToWork.MandatoryTrainingCertificate,
		].includes(docType)
	) {
		return true;
	}

	return false;
};

export const FlagDocumentExpiration = (documentType?: string, expiry?: string): { icon: JSX.Element; message: string } | null => {
	if (!expiry || !documentType) return null;

	let docType = typeof documentType === "string" ? parseInt(documentType) : documentType;

	const expiryDate = moment(expiry);
	const today = moment();

	if (
		[
			RightToWork.DrivingLicense,
			RightToWork.EuropeanPassport,
			RightToWork.UKPassport,
			RightToWork.Visa,
			RightToWork.RTWVerification,
			RightToWork.HomeOfficeShareCode,
			RightToWork.ForeignPassport,
			RightToWork.SafeguardingCertificate,
			RightToWork.SafeguardingCertificateWithExpiry,
			RightToWork.DfeCheck,
			RightToWork.EWCConfirmationInternalOnly,
			RightToWork.Other,
			RightToWork.BiometricResidencePermit,
			RightToWork.Qualification,
			RightToWork.BasicLifeSupportCertificate,
			RightToWork.PreventTraining,
			RightToWork.MandatoryTrainingCertificate,
			RightToWork.ManualHandlingTraining,
		].includes(docType)
	) {
		const threeMonthsFromToday = moment().add(3, "months");
		if (expiryDate.isBetween(today, threeMonthsFromToday)) {
			return {
				icon: <StatusSummaryCustomIcon variant="amber" />,
				message: "trafficlight--amber",
			};
		}
		if (expiryDate.isBefore(today)) {
			return {
				icon: <StatusSummaryCustomIcon variant="red" />,
				message: "trafficlight--red",
			};
		}
	} else if ([RightToWork.DBS].includes(docType)) {
		const sixMonthsFromToday = moment().add(6, "months");
		if (expiryDate.isBetween(today, sixMonthsFromToday)) {
			return {
				icon: <StatusSummaryCustomIcon variant="amber" />,
				message: "trafficlight--amber",
			};
		}
		if (expiryDate.isBefore(today)) {
			return {
				icon: <StatusSummaryCustomIcon variant="red" />,
				message: "trafficlight--red",
			};
		}
	}

	return {
		icon: <StatusSummaryCustomIcon variant="green" />,
		message: "trafficlight--green",
	};
};

export const FormatDate = (inputDate: string, format: string): string => {
	try {
		// Parse the inputDate using moment
		const date = moment(inputDate, ["YYYY-MM-DD", "DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD", "DD-MM-YYYY", "DD.MM.YYYY", "YYYY.MM.DD"]);

		// If the date is valid, format it with the provided format
		if (date.isValid()) {
			return date.format(format);
		}
	} catch (error) {
		// If any error occurs, return an empty string
		return "";
	}

	// If the date is not valid or any other exception, return an empty string
	return "";
};

export const ConvertAgreeDisagree = (value: AgreeDisagreeEnum) => {
	if (value === AgreeDisagreeEnum.Agree) {
		return "Agree";
	} else if (value === AgreeDisagreeEnum.Disagree) {
		return "Disagree";
	} else {
		return "Not Applicable";
	}
};

export const ConvertTargetType = (type: TargetTypes) => {
	if (type === TargetTypes.DaysOut) return "Days Out";
	if (type === TargetTypes.GrossProfit) return "Gross Profit";
	if (type === TargetTypes.CandidateUse) return "Individual Candidates Booked";
	if (type === TargetTypes.ClientUse) return "Individual Clients Booked";
	if (type === TargetTypes.HoursOut) return "Hours Out";
	if (type === TargetTypes.ClientUtilisation) return "Client Utilisation Percentage";
	return "N/A";
};

export const ConvertTargetPeriod = (period: number) => {
	if (!period || period == 1) return "Full";
	else if (period == 2) return "Weekly";
	else if (period == 3) return "Monthly";
	return "N/A";
};

export const ConvertRightToWork = (docType: RightToWork): string => {
	if (docType == RightToWork.UKPassport) return "UK Passport";
	else if (docType == RightToWork.BirthCertificate) return "Birth Certificate";
	else if (docType == RightToWork.EuropeanPassport) return "European Passport";
	else if (docType == RightToWork.Visa) return "Visa";
	else if (docType == RightToWork.Other) return "Other";
	else if (docType == RightToWork.DBS) return "DBS";
	else if (docType == RightToWork.Qualification) return "Qualification";
	else if (docType == RightToWork.ProofOfAddress) return "Proof of Address";
	else if (docType == RightToWork.DrivingLicense) return "Driving License";
	else if (docType == RightToWork.ProhibitionCheck) return "Prohibition Check";
	else if (docType == RightToWork.QTSCertificate) return "QTS Certificate";
	else if (docType == RightToWork.Reference) return "Reference";
	else if (docType == RightToWork.DBSUpdateService) return "DBSUpdateService";
	else if (docType == RightToWork.HomeOfficeShareCode) return "Home Office Share Code";
	else if (docType == RightToWork.CV) return "CV";
	else if (docType == RightToWork.NationalInsurance) return "National Insurance";
	else if (docType == RightToWork.SafeguardingCertificate) return "Safe guarding Certificate";
	else if (docType == RightToWork.RTWVerification) return "RTW Verification";
	else if (docType == RightToWork.InterviewNotes) return "Interview Notes";
	else if (docType == RightToWork.ProofOfNameChange) return "Proof Of Name Change";
	else if (docType == RightToWork.OverseasCriminalRecordCheck) return "Overseas Criminal Record Check";
	else if (docType == RightToWork.EWCConfirmation) return "EWC Confirmation";
	else if (docType == RightToWork.EWCConfirmationInternalOnly) return "EWC Confirmation (Internal Only)";
	else if (docType == RightToWork.ImposterCheck) return "Imposter Check";
	else if (docType == RightToWork.RTWPhoto) return "RTW Photo Proof";
	else if (docType == RightToWork.DeclarationPack) return "Declaration Pack";
	else if (docType == RightToWork.ImmunisationReport) return "Immunisation Report";
	else if (docType == RightToWork.GMCCertificate) return "GMC Certificate";
	else if (docType == RightToWork.NMCCertificate) return "NMC Certificate";
	else if (docType == RightToWork.HCPCCertificate) return "HCPC Certificate";
	else if (docType == RightToWork.BasicLifeSupportCertificate) return "Basic Life Support Certificate";
	else if (docType == RightToWork.BiometricResidencePermit) return "Biometric Residence Permit";
	else if (docType == RightToWork.Disclaimer) return "Disclaimer";
	else if (docType == RightToWork.OnlineSearch) return "OnlineSearch";
	else if (docType == RightToWork.MandatoryTrainingCertificate) return "Mandatory Training Certificate";
	else if (docType == RightToWork.ProofOfBankAccount) return "Proof Of Bank Account";
	else if (docType == RightToWork.ApplicationRegistrationForm) return "Application Registration Form";
	else if (docType == RightToWork.ProofOfNMCRegistration) return "Proof Of NMC Registration";
	else if (docType == RightToWork.StaffProfile) return "Staff Profile";
	else if (docType == RightToWork.CertificateOfIncorporation) return "Certificate Of Incorporation";
	else if (docType == RightToWork.ProofOfBusinessBankAccount) return "Proof Of Business Bank Account";
	else if (docType == RightToWork.BusinessInsurance) return "Business Insurance";
	else if (docType == RightToWork.VATCertificate) return "VAT Certificate";
	return "";
};

export const CanVerify = (fileType: string) => {
	const imageTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf", "application/octet-stream"];
	if (!fileType) return false;
	return imageTypes.includes(fileType.toLowerCase());
};

export const CanParse = (fileType: string) => {
	const fileTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
	if (!fileType) return false;
	return fileTypes.includes(fileType.toLowerCase());
};

export const IsNonUk = (formQuestions: IFormQuestion[]) => {
	return formQuestions?.find((question) => question.type === FormQuestionModuleType.candidate && question.question === FormQuestionType.nonUk);
};

export const EncodeObjectToBase64 = (obj: any) => {
	if (!obj) return null;
	try {
		const st = btoa(JSON.stringify(compress(obj)));
		return st;
	} catch (err) {
		return null;
	}
};

export const DecodeObjectToBase64 = (encodedString: string) => {
	if (!encodedString) return null;
	try {
		const st = decompress(JSON.parse(atob(encodedString)));
		return st;
	} catch (err) {
		return null;
	}
};

export const GetDefaultFilter = (filterBase64: string, filterType: FilterTableType) => {
	if (!filterBase64) return null;
	const filter = DecodeObjectToBase64(filterBase64);
	if (!filter || !filter[filterType]) return null;
	return filter[filterType];
};

export const GetAcceptedFileTypes: () => string[] = () => {
	const acceptedFileTypes: string[] = [
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.oasis.opendocument.text",
		"image/jpeg",
		"image/jpg",
		"image/png",
		"image/gif",
		"image/heic",
		"image/heif",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.oasis.opendocument.spreadsheet",
		"text/plain",
	];
	return acceptedFileTypes;
};

export const ValidateFileType: (inputType: string, acceptTypes: string[]) => boolean = (inputType: string, acceptedTypes: string[]) => {
	return acceptedTypes.includes(inputType);
};

export const ValidateFileSize: (inputSize: number, maxiumumSize: number) => boolean = (inputSize: number, maximumSize: number) => {
	const setMaximumSizeInMegaBytes: (sizeInMegaBytes: number) => number = (sizeInMegabytes: number) => {
		let megaByte = 1048576;
		return sizeInMegabytes * megaByte;
	};
	const isValidSize = inputSize <= setMaximumSizeInMegaBytes(maximumSize);
	return isValidSize;
};

export const ConvertCandidateOnboardingStep = (step: string) => {
	if (step === "contactInformation") return "General Information";
	if (step === "address") return "Address";
	if (step === "nextOfKin") return "Emergency Contact";
	if (step === "qualifications") return "Qualifications";
	if (step === "employmentHistory") return "Employment History";
	if (step === "experienceSummary") return "Experience Summary";
	if (step === "references") return "References";
	if (step === "socialMedia") return "Social Media";
	if (step === "criminalRecord") return "Criminal Record & Security Checks";
	if (step === "safeInEducation") return "Keeping Children Safe in Education";
	if (step === "healthRecord") return "Health Declaration";
	if (step === "documents") return "Documents";
	if (step === "bankDetails") return "Bank Details";
	if (step === "profilePhoto") return "Profile Photo";
	if (step === "declaration") return "Declaration";
	if (step === "contractForService") return "Contract for Service";
	return "";
};

export const UnsetNestedId = (obj: Record<string, any>): Record<string, any> => {
	if (obj !== null && typeof obj === "object") {
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (key === "_id") {
					obj[key] = null;
				} else if (typeof obj[key] === "object" && obj[key] !== null) {
					obj[key] = UnsetNestedId(obj[key] as Record<string, any>);
				}
			}
		}
	}
	return obj;
};
